.comics-modal{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  background: rgba(0, 0, 0, .5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: Modal_animation .5s forwards;
}

@keyframes Modal_animation {
  0% {opacity: 0}
  100% {opacity: 1}
}

.comics-modal__body{
  position: absolute;
  background: white;
  border-radius: 20px;
  min-width: 250px;
  max-width: 835px;
  max-height: 487px;
  //max-height: 90vh;
  //overflow-y: auto;
  scrollbar-width: none;
  display: flex;
  flex-direction: row;
  background: url("../../assets/modal-comics/banner-commics-back.webp");
  background-repeat: no-repeat;
  background-size: cover;
  //padding: 2rem;
  @media screen and (max-width: 900px){
    margin: 0 1rem;
    flex-direction: column;
  }
  @media screen and (max-width: 600px){
    background: url("../../assets/modal-comics/banner-comics-back-mobile.webp");
    background-size: cover;
    background-position: center;
  }
}

.comics-modal.active{
  display: flex;
  justify-content: center;
  align-items: center;
}

.comics_modal__body-close{
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
  color: white;
  z-index: 99;
  font-size: 2rem;
}

.comics-modal__left{
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-evenly;
  padding: 2rem;
  @media screen and (max-width: 900px){
    width: 100%;
  }

  &-title{
    color: white;
    font-family: Montserrat-Alternates-Bold;
    font-size: 34px;
    @media screen and (max-width: 900px){
      font-size: 22px;
    }
  }

  &-text{
    color: white;
    font-weight: 400;
    font-size: 20px;
    @media screen and (max-width: 900px) {
      font-size: 12px;
    }
    @media screen and (max-width: 480px) {
      margin-bottom: 1rem;
    }
    @media screen and (max-width: 400px) {
      
    }
    &-up{
      @media screen and (max-width: 900px) {
        display: block;
        width: 50%;
      }
      @media screen and (max-width: 480px) {
        width: 80%;
      }
    }
    &-down{
      @media screen and (max-width: 900px) {
        display: block;
        width: 50%;
      }
      @media screen and (max-width: 480px) {
        display: block;
        width: 50%;
      }
    }
  }

  &-white{
    background-color: white;
    padding: 1rem;
    border-radius: 20px;
    font-weight: 600;
    font-size: 18px;
    @media screen and (max-width: 900px) {
      display: none;
    }
  }

  &-buttons{
    display: flex;
    flex-direction: row;
    position: relative;
    @media screen and (max-width: 900px) {
      margin-top: 10rem;
    }
    @media screen and (max-width: 480px) {
      margin-top: 3rem;
    }

    &-desktop{
      background: white;
      color: #5A78E8;
      font-size: 20px;
      border-radius: 100px;
      border: 0;
      padding: 1rem 2rem;
      cursor: pointer;
      @media screen and (max-width: 900px) {
        display: none;
      }
    }

    &-mobile{
      background-color: white;
      border: 0;
      border-radius: 50px;
      padding: 1rem 1.3rem;
      cursor: pointer;
      @media screen and (min-width: 901px) {
        display: none;
      }
    }
  }
}

.comics-modal__right{
  position: relative;
  width: 50%;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
}

.br-first{
  @media screen and (max-width: 900px) {
    display: none;
  }
}

.comics-modal__right-img{
  position: absolute;
  width: 500px;
  right: -30px;
  bottom: 0;
  z-index: 10000;
  border-radius: 0 0 60px 0;
  @media screen and (max-width: 900px){
    padding-right: 1rem;
    width: 365px;
    right: -35px;
    border-bottom-right-radius: 60px;
  }
  @media screen and (max-width: 540px) {
    width: 250px;
  }
  //clip-path: inset(0 0 200px 0);
}

.white-arrow{
  width: 260px;
  position: absolute;
  right: 0;
  @media screen and (max-width: 900px) {
    display: none;
  }
}